@import 'npm:bootstrap/dist/css/bootstrap.css';

@import 'npm:fullcalendar/dist/fullcalendar.min.css';
@import 'npm:fullcalendar-scheduler/dist/scheduler.min.css';

/* previously conferences_table.css */

#conferences_table {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 75vh;
}

/* previously navbar.css */

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  padding-top: 50px;
  padding-bottom: 20px;
}

body.masthead {
  transition: 0.4s;
}
body.masthead.mastheadopen {
  padding-top: 120px;
}

body.masthead .navbar {
  transition: 0.4s;
}
body.masthead.mastheadopen .navbar {
/*  min-height: 100px; */
}

body.masthead .navbar-brand {
  transition: 0.4s;
}
body.masthead.mastheadopen .navbar-brand {
  font-size: 64px;
  height: 100px;
  line-height: 80px;
  color: white;
}

body.masthead .breadcrumb {
  display: none;
}

/* previously vertical-align.css */

.vertical-align {
  display: flex;
  align-items: center;
}

.chart-container {
  width: 100%;
}
